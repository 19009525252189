import React from 'react';
import ReviewSection from "./ReviewSection";
import AboutUsSection from "./AboutUsSection";
import HeroHome from "./HeroHome";
import FeaturedTours from "./FeaturedTours";
import Contact from "./Contact";

export default function HomePage() {
  document.title = "Sentir Marruecos | Home";

  return (
    <div className="grow main-content">
      <HeroHome/>
      <FeaturedTours/>
      <ReviewSection/>
      <AboutUsSection/>
      <Contact/>
    </div>
  );
}
