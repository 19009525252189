import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faInstagram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";

export default function Contact() {

  return (
    <section id="contacto" className={"pt-12 bg-orange-600"}>
      <div className={"mx-8 md:mx-16"}>
        <div className={"bg-gray-100 border-2 border-gray-900 border-b-0 shadow-lg rounded-t-lg py-16 px-8"}>
          <h2 className="text-center text-4xl font-extrabold text-gray-900">Póngase en contacto con nosotros</h2>
          <div className="flex flex-col text-xl gap-16 mt-8">
            <div className="flex flex-col justify-center items-center gap-4 mt-8">
              <div className="flex flex-col items-center gap-4 bg-gray-50 rounded-lg p-4 shadow-md w-full max-w-xl">
                <FontAwesomeIcon icon={faPhoneAlt} className="text-3xl text-orange-500" />
                <p className="text-gray-700 text-sm md:text-xl">(+212) <b>658 821 953</b></p>
              </div>
              <div className="flex flex-col items-center gap-4 bg-gray-50 rounded-lg p-4 shadow-md w-full max-w-xl">
                <FontAwesomeIcon icon={faEnvelope} className="text-3xl text-orange-500" />
                <p className="text-gray-700 text-sm md:text-xl"><b>sentirmarruecosviajes@gmail.com</b></p>
              </div>
            </div>
            <div className="flex justify-center items-center gap-8">
              <a href="https://wa.me/212658821953" className="text-3xl text-orange-500 p-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a href="https://www.instagram.com/sentir_marruecos_viajes/" className="text-3xl text-orange-500 p-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100078748751382" className="text-3xl text-orange-500 p-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="bg-white rounded-t-lg px-4 py-12 max-w-6xl mx-auto border-2 border-gray-900 border-b-0 shadow-lg">

      </div>*/}
    </section>
  );
}


