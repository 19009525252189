import React from 'react';
import './styles/AboutUsSection.css';
import './_Home.css';

export default function AboutUsSection() {

  let aboutUs = [
    {
      imageSrc: "/home/aboutUs-1.jpg",
      imageAlt: 'Clientes de Sentir Marruecos cabalgando camellos',
      description: <span>En Sentir Marruecos nos apasiona compartir la <span className={'font-bold bg-gray-900 text-orange-600 px-1 rounded'}>riqueza cultural</span> y natural de nuestro país con viajeros de todo el mundo. Fundada en 2005, somos una agencia de viajes especializada en ofrecer <span className={'font-bold bg-gray-900 text-orange-600 px-1 rounded'}>experiencias personalizadas</span> y únicas.</span>
    },
    {
      imageSrc: "/home/aboutUs-2.jpg",
      imageAlt: 'Clientes de Sentir Marruecos saltando',
      description: <span>Nuestra misión en cada tour es brindar un <span className={'font-bold bg-gray-900 text-orange-600 px-1 rounded'}>servicio excepcional</span> y experiencias inolvidables a todos nuestros clientes, siempre promoviendo la sostenibilidad y el desarrollo local. En Sentir Marruecos creemos en nuestros valores y los respetamos en cada viaje.</span>
    },
    {
      imageSrc: "/home/aboutUs-3.jpg",
      imageAlt: 'El equipo de Sentir Marruecos',
      description: <span>En Sentir Marruecos nos enorgullece nuestro <span className={'font-bold bg-gray-900 text-orange-600 px-1 rounded'}>compromiso</span> con la calidad, la innovación y la atención al detalle. A lo largo de los años, hemos construido una sólida reputación gracias a nuestros tours únicos y personalizados, fundados en valores de pasión y dedicación.</span>
    }
  ]

  let team = [
    {
      name: 'Salem',
      languages: ['🇪🇸 🇲🇦 🇬🇧'],
      description: 'Es el encargado de las reservas y de asegurarse de que todo está a gusto de los viajeros. Nacido entre las dunas del desierto y formado en turismo, es la combinación perfecta entre conocimiento del territorio y competencia técnica.'
    },
    {
      name: 'Hassan',
      languages: ['🇪🇸 🇬🇧 🇵🇹 🇮🇹'],
      description: 'Gracias a su gran experiencia en el sector del turismo receptivo en Marruecos conoce cada rincón de la región y es un apasionado de la gastronomía y de la historia cultural de Marruecos.'
    },
    {
      name: 'Mohamed',
      languages: ['🇪🇸 🇬🇧 🇲🇦 🇫🇷'],
      description: 'Nacido en el desierto y con 8 años de experiencia a sus espaldas, lo sabe todo sobre Marruecos y su cultura. Disfruta y descubre los secretos de este país con él.'
    }
  ];

  return (
    <section id="equipo" className="bg-orange-600">
      <div>
        <div className="relative h-20 sm:h-32 md:h-40">
          <div className="desert-divider">
            <svg viewBox="0 0 1200 120" preserveAspectRatio="none" className="h-10 md:h-16 lg:h-24 fill-gray-100">
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"></path>
            </svg>
          </div>
        </div>
      </div>

      <div className={"mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24"}>
        <div>
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-gray-900">¿Quién somos en Sentir Marruecos?</h2>

          <div className={"mx-auto max-w-screen-xl flex flex-col gap-y-12 text-lg mt-8"}>

            {
              aboutUs.map((item, index) => {
                return (
                  <div className="flex flex-col md:flex-row items-center w-full">
                    <img
                      className="w-full md:w-1/2 h-64 object-cover transition-transform duration-500 hover:scale-[1.03] rounded-lg z-10"
                      src={item.imageSrc}
                      alt={item.imageAlt}
                    />
                    <div className={"w-full md:w-1/2"}>
                      <p className="mx-4 text-gray-900 bg-gray-300 rounded-b-lg md:rounded-tl-none md:rounded-bl-none md:rounded-r-lg md:mx-0 p-4 text-lg shadow">
                        {item.description}
                      </p>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>

      <div className={"mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24"}>
        <h2 className={"text-3xl font-bold tracking-tight sm:text-4xl text-gray-900"}>
          Conoce al equipo de Sentir Marruecos
        </h2>
        <p className={"py-4 mt-4 mx-auto lg:max-w-7xl text-gray-900 text-xl"}>
          El equipo de Sentir Marruecos lo componemos <span className={'font-black'}>tres personas apasionadas</span> por la cultura de nuestro país. Nos encanta compartir nuestras historias, tradiciones y conocimientos con aquellos que nos visitan. Como guías turísticos, estamos comprometidos a brindarle una <span className={'font-black'}>experiencia única y auténtica</span> en Marruecos.
        </p>
        <div className="grow">
          <div className="mx-auto max-w-screen-xl sm:p-8 z-10 relative">
            <div className="flex flex-wrap justify-center text-center">
              {
                team.map((member, index) => (
                  <div className="overflow-hidden w-full md:w-1/2 lg:w-1/3 flex ">
                    <div className={"p-4 h-full"}>
                      <div className={"p-6 bg-gray-200 rounded-lg shadow h-full flex flex-col border-4 border-gray-300"}>
                        <img className="w-40 h-40 rounded-full mx-auto bg-orange-200 object-cover" src={`/team/team${index + 1}.jpeg`} alt="Team member"/>
                        <div className="py-4 flex flex-col justify-between flex-grow">
                          <div className="font-bold text-2xl mb-4">{member.name}</div>
                          <div className={"flex flex-row justify-center gap-1 mb-4 text-2xl rounded"}>
                            {member.languages}
                          </div>
                          <p className={"flex-grow"}>{member.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
