import React from 'react';
import './styles/FeaturedTours.css';
import {Link} from "react-router-dom";

export default function FeaturedTours() {

  const tours = [
    {
      name: "Descúbrete en Marrakesh",
      description: "Visita la gran ciudad de Marrakech y sus secretos mejor guardadados"
    },
    {
      name: "Sorpréndete en Fez",
      description: "Piérdete por la gran medina de Fez y conoce sus preciosas artesanías"
    },
    {
      name: "Piérdete en Tánger",
      description: "Déjate sorprender por Tánger y todos los pueblos que la rodean"
    }
  ];

  return (
    <section id="experiencias" className="py-32 fill-gray-800">
      <div className={"mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24"}>
        <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-gray-200">Nuestras experiencias preferidas</h2>
        <p className="text-gray-300 mt-4 max-w-3xl text-lg">
          Explora Marruecos con nuestras experiencias seleccionadas especialmente para ti. Descubre la magia y la cultura de este hermoso país a través de nuestras visitas guiadas privadas y grupales.
        </p>
        <div className="flex flex-wrap justify-center py-4">
          {tours.map((tour, index) => (
            <div key={index} className={`w-full md:w-1/2 lg:w-1/3 p-4 tour-${index + 1}`}>
              <div className={'bg-white p-5 rounded-lg'}>
                <div className="relative h-64 mb-4 rounded-lg overflow-hidden shadow-lg">
                  <img
                    className="w-full h-full object-cover filter grayscale"
                    src={`/home/tourFeatured-${index + 1}.jpg`}
                    alt={tour.name}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t to-orange-600 from-gray-700 opacity-50"></div>
                  <h3 className="absolute bottom-0 mb-4 ml-4 text-xl font-bold text-white">{tour.name}</h3>
                </div>
                <p className="text-gray-600">{tour.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={"w-full flex flex-row justify-center"}>
          <Link to={"/rutas"}>
            <button className="bg-orange-600 hover:bg-orange-600/80 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
              <span>Descubre nuestra rutas</span>
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
