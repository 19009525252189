import React from 'react';
import './styles/ReviewSection.css';
import ReviewSwiper from "./ReviewSwiper";

export default function ReviewSection() {
  return (
    <section className="bg-gray-100">
      <div className="relative z-0">
        <div className="curve-divider">
          <svg className="h-10 md:h-16 lg:h-24 curve-divider-shadow" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V6c0,21.6,291,111.46,741,110.26,445.39,3.6,459-88.3,459-110.26V0Z" className="fill-gray-800"></path>
          </svg>
        </div>
      </div>
      <div className="mx-auto max-w-screen-xl p-4 sm:p-8 z-10 relative -top-16">
        <div className="w-full bg-gray-200 text-gray-800 rounded-2xl p-5 sm:p-8 shadow-md">
          <div className="flex flex-row place-content-between flex-wrap items-center">
            <div>
              <div className="flex flex-row gap-3 md:gap-5 lg:gap-8 items-center place-content-between">
                <a href="https://www.tripadvisor.es/Attraction_Review-g304017-d7708904-Reviews-Siente_Marruecos-Merzouga_Draa_Tafilalet.html" rel="noopener noreferrer" target="_blank"><img src="/TripAdvisorLogo.svg" alt="Logo Tripadvisor" className="h-8 sm:h-12 hover:cursor-pointer"/></a>
                <span className="text-xl sm:text-3xl">Ratings</span>
              </div>
              <div className="flex flex-row flex-wrap gap-3 mt-6 sm:mt-3 ml-2 items-center">
                <span className="font-bold text-2xl">5.0</span>
                <div className="flex flex-row">
                  <img src="/RatingStar.svg" className="h-6" alt="Estrella de puntuación entera"/>
                  <img src="/RatingStar.svg" className="h-6" alt="Estrella de puntuación entera"/>
                  <img src="/RatingStar.svg" className="h-6" alt="Estrella de puntuación entera"/>
                  <img src="/RatingStar.svg" className="h-6" alt="Estrella de puntuación entera"/>
                  <img src="/RatingStar.svg" className="h-6" alt="Estrella de puntuación entera"/>
                </div>
                <div className="basis-full h-0 sm:hidden"></div>
                <a
                  href="https://www.tripadvisor.com/ShowUserReviews-g304017-d7708904-r581353108-Siente_Marruecos-Merzouga_Draa_Tafilalet.html#REVIEWS"
                  className="text-gray-600 text-lg -mt-6 sm:mt-0 hover:underline hover:cursor-pointer decoration-gray-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  +170 opiniones
                </a>
              </div>
            </div>
            <div className="grow"></div>
            <a className="bg-gray-800 text-gray-200 py-3 px-5 rounded-xl mt-8 sm:mt-0 font-bold hover:cursor-pointer text-sm" href="https://www.tripadvisor.es/UserReviewEdit-d7708904" target="_blank" rel="noopener noreferrer">
              Dejar tu opinión
            </a>
            <div className="grow sm:hidden"></div>
          </div>
        </div>
        <ReviewSwiper/>
      </div>
    </section>
  );
}
