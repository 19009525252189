import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './_Home.css';

export default function HeroHome() {
  const phrases = [
    'la Magia',
    'la Aventura',
    'la Cultura',
    'el Encanto',
    'la Belleza',
    'la Historia',
    'los Misterios',
    'la Vida',
    'las Maravillas',
    'el Calor'
  ];

  const images = [
    'heroHome-1.JPG',
    'heroHome-2.JPG',
    'heroHome-3.JPG',
    'heroHome-4.JPG',
    'heroHome-5.JPG',
    'heroHome-6.JPG',
    'heroHome-7.JPG',
    'heroHome-8.JPG',
    'heroHome-9.JPG',
    'heroHome-10.JPG'
  ];

  const [phraseIndex, setPhraseIndex] = useState(0);
  const [titleAnimation, setTitleAnimation] = useState('translate-y-5 opacity-0');
  const [subtitleAnimation, setSubtitleAnimation] = useState('translate-y-5 opacity-0');

  useEffect(() => {
    setTitleAnimation('translate-y-5 opacity-0');
    setSubtitleAnimation('translate-y-5 opacity-0');
    setTimeout(() => {
      setTitleAnimation('translate-y-0 opacity-100');
      setSubtitleAnimation('translate-y-0 opacity-100');
    }, 50);
  }, []);

  useEffect(() => {
    const interval = setInterval(handlePhraseClick, 3000);
    return () => clearInterval(interval);
  }, [phraseIndex]);

  const handlePhraseClick = () => {
    setPhraseIndex((phraseIndex + 1) % phrases.length);
  };

  const onVisibilityChange = (isVisible: any) => {
    if (isVisible) {
      setTitleAnimation('translate-y-0 opacity-100');
      setSubtitleAnimation('translate-y-0 opacity-100');
    }
  };

  return (
    <div id="home" className="min-h-screen">
      <section className="relative w-full h-screen">
        <div className="absolute inset-0 overflow-hidden">
          {images.map((image, index) => (
            <img
              key={image}
              src={`/home/${image}`}
              alt="Marruecos"
              className={`absolute inset-0 object-cover w-full h-full transition-opacity duration-500 ${
                index === phraseIndex ? 'opacity-100' : 'opacity-0'
              }`}
            />
          ))}
        </div>
        <div className="absolute inset-0 opacity-60 bg-black"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
          <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
            <div className={'hero-header select-none'}>
              <h1
                className={`tracking-wide transition-all duration-1000 ease-in-out transform ${titleAnimation} flex flex-col items-center gap-2 lg:flex-row lg:items-baseline`}
              >
                <span className={'text-xl md:text-3xl'}>
                  Descubre {' '}
                </span>
                <span
                  className="font-bold text-4xl md:text-5xl inline-block px-2 md:px-5 py-1 sm:py-3 mx-1 text-white bg-orange-500 rounded cursor-pointer hover:bg-orange-600 transition shadow-2xl"
                  onClick={handlePhraseClick}
                >
                  {phrases[phraseIndex]}
                </span>
                <span className={'text-xl md:text-3xl'}>
                  {' '} de Marruecos
                </span>
              </h1>
              <h2
                className={`mt-8 lg:mt-4 text-sm sm:text-lg text-center transition-all duration-1000 ease-in-out delay-300 transform ${subtitleAnimation}`}
              >
                con <span className={'font-bold'}>Sentir Marruecos</span>
              </h2>
            </div>
          </VisibilitySensor>
        </div>
        <div className="diagonal-separator bg-gray-700 w-full h-28 bottom-0"></div>
      </section>
    </div>
  );
}
