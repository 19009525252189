import React from 'react';
import Footer from "./home/Footer";
import HeaderNav from "./_common/HeaderNav";
import RoutesPage from "./routes/RoutesPage";
import {Route, Routes} from "react-router-dom";
import HomePage from "./home/HomePage";
import ExcursionsPage from "./excursions/ExcursionsPage";
import TeamPage from "./team/TeamPage";
import Contact from "./home/Contact";
import RoutePage from "./routes/RoutePage";

import TagManager from 'react-gtm-module';

export default function App() {

  const tagManagerArgs = {
    gtmId: 'G-FMXX3HNZGW'
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <div className="App flex flex-col min-h-screen bg-gray-800">
      <HeaderNav/>
      <Routes>
        <Route path="/" element={<HomePage />}  />
        <Route path="/rutas" element={<RoutesPage/>} />
        <Route path="/rutas/:routeDeparture/:routeName/:routeIndex" element={<RoutePage />} />
        <Route path='*' element={<HomePage />}/>

        {/*<Route path="/excursiones" element={<ExcursionsPage />} />*/}
        {/*<Route path="/equipo" element={<TeamPage />} />*/}
        {/*<Route path="/contacto" element={<Contact />} />*/}
      </Routes>
      <Footer/>
    </div>
  );
}
