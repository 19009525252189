import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Navigation, Pagination} from "swiper";

export default function ReviewSwiper() {

  let tripadvisorOpinionsData = [
    {
      url: "https://www.tripadvisor.es/ShowUserReviews-g304017-d7708904-r871528427-Siente_Marruecos-Merzouga_Draa_Tafilalet.html",
      user: "Juanca A",
      title: "Aventura inolvidable con la mejor compañía posible como guía",
      body: "Hemos vivido una aventura inolvidable con nuestro guia y gran amigo desde el primer día Salem. El cariño y gran corazón con el que nos ha tratado y nos ha mostrado cada rincón de su tierra y cultura es de admirar. Hemos descubierto paisajes, personas, familias y anécdotas que nunca olvidaremos y nos han sorprendido enormemente. Es una experiencia recomendable totalmente.\n" +
        "Salem amigo muchas gracias por todo, ya eres como de la familia, volveremos! Saludos de Juan Carlos, Pedro y el gordito!",
      date: "12 de diciembre de 2022"
    },
    {
      url: "https://www.tripadvisor.es/ShowUserReviews-g304017-d7708904-r870525596-Siente_Marruecos-Merzouga_Draa_Tafilalet.html",
      user: "Paula E",
      title: "Excelente excursión y guía",
      body: "Hicimos la excursión desde Marrakech a Ouarzazate. Éramos 4 y nos llevaron con un Toyota land cruiser, por lo que el viaje fue super cómodo. Nuestro conductor, Lahsen hizo que la excursión fuera inmejorable. Estuvo pendiente constantemente de si necesitábamos parar, de que nos sintiéramos a gusto en todo momento, de parar en los mejores sitios para hacer fotos. Fue super atento con nosotras, nos hizo el viaje ameno y nos contó muchos datos interesantes. El guía de Telouet fue muy amable, nos lo explicó todo muy bien y nos hizo reír mucho. El de Ait Ben Haddou no nos gustó, no hablaba bien español y no se entendía nada. Recomiendo muchísimo hacer esta excursión, merece la pena totalmente, parece que es mucho rato pero no se nos hizo pesado el viaje.",
      date: "3 de diciembre de 2022"
    },
    {
      url: "https://www.tripadvisor.es/ShowUserReviews-g304017-d7708904-r870234326-Siente_Marruecos-Merzouga_Draa_Tafilalet.html",
      user: "Ada C",
      title: "Descubriendo Ouarzazate con el mejor guia",
      body: "Realizamos la excursion de un día a Ouarzazate desde Marrakech con el chofer Lahsen y fue una expeciencia increbíble!\n" +
        "\n" +
        "La excursión tuvo una duración de 12 horas aproximadamente y fue muy completa. El chofer Lahsen se encargaba de enseñarnos sitios increibles y contarnos curiosidades de la cultura de la zona. Sin duda uno de los mejores momentos fue el cafe que nos tomamos viendo el amanecer y la comida que realizamos en un pueblo típico de la zona.\n" +
        "\n" +
        "La visita contaba con dos guías locales en cada una de las paradas.\n" +
        "\n" +
        "Sin duda es una experiencia que repetiríamos al 100% y muy recomendable",
      date: "30 de noviembre de 2022"
    },
    {
      url: "https://www.tripadvisor.es/ShowUserReviews-g304017-d7708904-r869033130-Siente_Marruecos-Merzouga_Draa_Tafilalet.html",
      user: "Cristina V",
      title: "Marruecos es maravilloso",
      body: "Un viaje muy bonito, el desierto inolvidable, los guías muy amables, Salem siempre atento y mostrando todo con detalle, muy recomendable por ambas cosas, repetiría la noche en las haimas 1000 veces y la ruta en 4x4 por las dunas es también una pasada",
      date: "19 de noviembre de 2022"
    },
    {
      url: "https://www.tripadvisor.es/ShowUserReviews-g304017-d7708904-r867003619-Siente_Marruecos-Merzouga_Draa_Tafilalet.html",
      user: "Carolina A",
      title: "TOUR EN 4x4 privado",
      body: "NO SON TOURS EN AUTOBÚS\n" +
        "Veo algún comentarios que pone esto y no es cierto, se han debido de confundir de empresa a la hora de buscarlo por aquí.\n" +
        "He hecho 2 viajes con esta empresa y los dos han sido increíbles, nuestro propio coche 4X4, viajes personalizado y privado, con todas las comodidades, todos los conductores son un encanto, te responden las dudas que tengas y hacen que conozcas la cultura al 100%.\n" +
        "Volveré a repetir sin duda alguna, Marruecos es inmenso y siempre hay cosas que conocer.",
      date: "2 de noviembre de 2022"
    },
    {
      url: "https://www.tripadvisor.es/ShowUserReviews-g304017-d7708904-r866865234-Siente_Marruecos-Merzouga_Draa_Tafilalet.html",
      user: "Ana M",
      title: "3 noches en el desierto INOLVIDABLES. Con Salem y Hasan.",
      body: "INCREÍBLE. Hicimos un tour de 4 noches.\n" +
        "3 de ellas en el desierto acompañados en todo momento de Salem. Trato espectacular.\n" +
        "Hicimos ruta de buggies, dormimos en jaimas a la luz de las estrellas, cenamos a la luz de la noche solos en el desierto...\n" +
        "Las jaimas, donde también nos recibió su hermano Hasan, son de lujo. Hasta el cuarto de baño (privado) tiene suelo. Todo está impecable, la comida exquisita.\n" +
        "Las jaimas están a tan sólo 4 minutos andando de las dunas, donde ver la salida y la puesta de sol es algo inolvidable.\n" +
        "Salem nos ha llevado a ver pueblos nómadas, a comer con su familia a su casa, a ver las antiguas minas de carbón... Todo eras atención al detalle!!!\n" +
        "\n" +
        "Sólo nos queda que decir GRACIAS y que volveremos.\n" +
        "\n" +
        "Los hemos recomendado a 5 grupos de amigos que están deseando ir después de ver nuestras fotos.\n" +
        "Trato EXQUISITO.",
      date: "1 de noviembre de 2022"
    },
  ]

  let slidesPerView = 1
  if (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 1024)
    slidesPerView = 2;

  return (
    <Swiper
      spaceBetween={16}
      slidesPerView={slidesPerView}
      pagination={true}
      navigation={true}
      modules={[Pagination, Navigation]}
    >
      {tripadvisorOpinionsData.map(review => (
        <SwiperSlide key={review.date}>
          <div className="bg-gray-200 text-gray-800 rounded-2xl p-5 sm:py-8 sm:px-12 mt-3 mb-10 flex flex-col justify-start gap-3 shadow-md">
            <a href={review.url} className="hover:underline" rel="noopener noreferrer" target="_blank"><h3 className="font-bold text-2xl">{review.user}</h3></a>
            <div className="flex flex-row">
              <img src="/RatingStar.svg" className="w-4" alt="Estrella de puntuación entera"/>
              <img src="/RatingStar.svg" className="w-4" alt="Estrella de puntuación entera"/>
              <img src="/RatingStar.svg" className="w-4" alt="Estrella de puntuación entera"/>
              <img src="/RatingStar.svg" className="w-4" alt="Estrella de puntuación entera"/>
              <img src="/RatingStar.svg" className="w-4" alt="Estrella de puntuación entera"/>
            </div>
            <a href={review.url} className="hover:underline" rel="noopener noreferrer" target="_blank"><h4 className="font-bold text-lg">{review.title}</h4></a>
            <p className="h-36 text-ellipsis overflow-hidden whitespace-pre-wrap">{review.body}</p>
            <a href={review.url} className="hover:underline" rel="noopener noreferrer" target="_blank"><div className="h-4 -mt-8 bg-gradient-to-b from-transparent to-gray-200"></div></a>
            <span className="text-gray-500 text-sm z-10 bg-gray-200 mt-4">Escrito el {review.date}</span>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
