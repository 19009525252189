import routesData from './routes.json';
import {Link} from "react-router-dom";

export default function RoutesPage() {
  document.title = "Sentir Marruecos | Rutas";
  window.scrollTo({ top: 0, left: 0});
  let routes = routesData.routes;

  return (
    <section className="container mt-20 mx-auto my-10 px-4 pb-28">
      <div className={"w-full mt-10"}>
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Nuestras rutas</h1>
        <p className="mb-6 text-lg font-normal lg:text-xl text-gray-400">¡Bienvenido a nuestras emocionantes rutas por Marruecos! Sumérgete en la magia y la belleza de este fascinante país a través de nuestros diversos circuitos cuidadosamente diseñados para satisfacer todos tus deseos de aventura. Desde la bulliciosa ciudad de Marrakech hasta la cautivadora Tánger, nuestras rutas te llevarán a descubrir los tesoros ocultos y los impresionantes paisajes del desierto marroquí.</p>
      </div>
      {routes.map((route, index) => (
        <div key={index}>
          <h2 className="text-2xl font-medium text-orange-600 mt-14">{route.name}</h2>
          <div className="mt-6 flex-col hidden md:block">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-3.5 text-left text-sm font-normal text-gray-500 rtl:text-right">
                        <span>Nombre de la ruta</span>
                      </th>
                      <th scope="col" className="px-12 py-3.5 text-left text-sm font-normal text-gray-500 rtl:text-right">Duración
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-left text-sm font-normal text-gray-500 rtl:text-right">Salida
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-left text-sm font-normal text-gray-500 rtl:text-right">Llegada
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {route.options.map((option, optIndex) => (
                      <tr key={optIndex}>
                        <td className="whitespace-nowrap px-4 py-4 text-sm font-medium">
                          <Link to={generateUrl(option.departure, option.title, optIndex)}>
                            <h2 className="font-medium text-gray-700">{option.title}</h2>
                          </Link>
                        </td>
                        <td className="whitespace-nowrap px-12 py-4 text-sm font-medium">
                          <div
                            className="inline gap-x-2 rounded-full bg-orange-600/10 px-3 py-1 text-sm font-normal text-orange-600">{option.duration} días
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm">
                          <h4 className="text-gray-600">{option.departure}</h4>
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm">
                          <h4 className="text-gray-600">{option.arrival}</h4>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className={"flex-row block md:hidden"}>
            <div className="flex flex-wrap justify-center -mb-4 -mx-2 mt-4">
              { route.options.map((option, optIndex) => (
                <div className="w-full sm:w-1/2 md:w-1/3 mb-4 px-2">
                  <div className="p-6 border rounded-lg shadow bg-white border-gray-700">
                    <Link to={generateUrl(option.departure, option.title, optIndex)}>
                      <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">{option.title}</h5>
                    </Link>
                    <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                      <dl className="grid gap-2 border-y border-gray-900/10 py-4 grid-cols-2 mt-10">
                        <div key={0}>
                          <dt className="text-sm font-semibold leading-6 text-gray-500">
                            Salida
                          </dt>
                          <dd className="text-lg font-bold leading-5 tracking-tight text-gray-600">
                            {option.departure}
                          </dd>
                        </div>
                        <div key={1}>
                          <dt className="text-sm font-semibold leading-6 text-gray-500">
                            Llegada
                          </dt>
                          <dd className="text-lg font-bold leading-5 tracking-tight text-gray-600">
                            {option.arrival}
                          </dd>
                        </div>
                      </dl>
                      <div className={"w-full flex items-center justify-center mt-4"}>
                        <div
                          className="inline gap-x-2 rounded-full bg-orange-600/10 px-3 py-1 text-sm font-normal text-orange-600">{option.duration} días
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

function generateUrl(departure: string, title: string, index: number): string {
  const normalizedDeparture = departure.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  const cleanedTitle = title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '').replace(/-+/g, '-');

  return `/rutas/desde-${normalizedDeparture}/${cleanedTitle}/${index}`;
}


