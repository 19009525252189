import React, { useState } from "react";
import { Link } from 'react-router-dom';

export default function HeaderNav() {
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <nav className="w-full bg-gray-900 shadow fixed top-0 z-50">
        <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <a href="/" className="flex items-center">
              <img src="/main-logo.png" className="mr-3 h-9" alt="Logo de Sentir Marruecos"/>
              <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Sentir Marruecos</span>
            </a>
            <div className="md:hidden">
              <button className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border" onClick={() => setNavbar(!navbar)}>
                {navbar ?
                  (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/>
                    </svg>
                  ) :
                  (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                  )
                }
              </button>
            </div>
          </div>
          <div className={`flex-1 justify-self-center pb-3 my-8 md:block md:pb-0 md:my-0 ${navbar ? "block" : "hidden"}`}>
            <ul className="items-center justify-end space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-white hover:text-orange-500 hover:cursor-pointer"><Link to={"/"}>Home</Link></li>
              <li className="text-white hover:text-orange-500 hover:cursor-pointer"><Link to={"/rutas"}>Rutas</Link></li>
{/*              <li className="text-white hover:text-orange-500 hover:cursor-pointer"><Link to={"/excursiones"}>Excursiones</Link></li>
              <li className="text-white hover:text-orange-500 hover:cursor-pointer"><Link to={"/equipo"}>Equipo</Link></li>
              <li className="text-white hover:text-orange-500 hover:cursor-pointer"><Link to={"/contacto"}>Contacto</Link></li>*/}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
